import { graphql, Link } from "gatsby";
import Markdown from "markdown-to-jsx";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import IconArrow from "../assets/icons/arrow-right.svg";
import { CarouselCards, CarouselScroll, Image, Layout } from "../components";
import * as styles from "../styles/project.module.scss";

class Project extends Component {
  render() {
    const projectIcons = this.props.data.projectIcons.nodes.map((item) => {
      item.text = "Learn more";
      return item;
    });
    const projectMdx = Object.fromEntries(
      this.props.data.projectMdx.nodes.map((mdx) => [
        mdx.name,
        mdx.childMdx?.body ?? mdx.publicURL,
      ])
    );

    return (
      <Layout layoutClassName="project-page">
        <div className={styles.projectpage}>
          <section className={styles.introduction}>
            <h6 className={styles.mobileHeader}>What We Do</h6>
            <Row className={styles.heroContainer}>
              <Col xs={1}>
                <Link to="/whatwedo/">
                  <IconArrow className={styles.backArrow} />
                </Link>
              </Col>
              <Col>
                <h1 className={styles.hero}>{projectMdx.title}</h1>
              </Col>
            </Row>
            <Row className={styles.body}>
              <Col lg={6}>
                <h6 className={styles.header}>{projectMdx.header}</h6>
              </Col>
              <Col className={styles.details} xs={12} lg={6}>
                <p>{projectMdx.introduction}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}></Col>
              <Col xs={12} lg={6}>
                <hr className={styles.computerHr} />
                <div className={styles.specs}>
                  <h2 className={styles.title}>Domain</h2>
                  <a
                    href={`https://${projectMdx.domain}`}
                    className={`${styles.text} ${styles.domain}`}
                  >
                    {projectMdx.domain}
                  </a>
                </div>
                <hr />
                <div className={styles.specs}>
                  <h2 className={styles.title}>Services</h2>
                  <Markdown className={styles.text}>
                    {projectMdx.services}
                  </Markdown>
                </div>
                <hr />
                <div className={styles.specs}>
                  <h2 className={styles.title}>Technologies</h2>
                  <Markdown className={styles.text}>
                    {projectMdx.technologies}
                  </Markdown>
                </div>
                <hr className={styles.computerHr} />
              </Col>
            </Row>
          </section>
          <Image
            src={projectMdx.banner}
            alt={`${projectMdx.title} banner`}
            className={styles.banner}
          />
          <section className={styles.description}>
            <hr className={styles.computerHr} />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>About the product</h6>
              </Col>
              <Col xs={12} lg={6}>
                <Markdown>{projectMdx.product}</Markdown>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>About the team</h6>
              </Col>
              <Col xs={12} lg={6}>
                <Markdown>{projectMdx.team}</Markdown>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Challenge</h6>
              </Col>
              <Col xs={12} lg={6}>
                <Markdown>{projectMdx.challenge}</Markdown>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Solution</h6>
              </Col>
              <Col xs={12} lg={6}>
                <Markdown>{projectMdx.solution}</Markdown>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Workflow</h6>
              </Col>
              <Col className={styles.workflow} xs={12} lg={6}>
                <Markdown>{projectMdx.workflow}</Markdown>
              </Col>
            </Row>
          </section>
          <section className={styles.otherProjects}>
            <div className={styles.title}>
              <h3>Other Projects</h3>
              <div className={styles.computerScroll}>
                <CarouselScroll />
              </div>
            </div>
            <div className={styles.mobileScroll}>
              <CarouselScroll />
            </div>
            <CarouselCards
              cards={projectIcons}
              urlPrefix="/whatwedo"
              imageOverlay
              showImage
              cardClass={styles.card}
            />
          </section>
        </div>
      </Layout>
    );
  }
}

export default Project;

export const query = graphql`
  query ($project: String!) {
    projectIcons: allFile(
      filter: { relativeDirectory: { eq: "assets/projects/cards" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
    projectMdx: allFile(filter: { relativeDirectory: { eq: $project } }) {
      nodes {
        childMdx {
          body
        }
        name
        publicURL
      }
    }
  }
`;
